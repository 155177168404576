import { find } from 'lodash';
import {
  FABRIC_PRODUCT_OPTION_ID,
  LEATHER_PRODUCT_OPTION_ID,
  VALID_IMAGE_OPTION_IDS,
  VALID_WOOD_OPTION_IDS,
} from './constants';

function getDefaultOptionData(product) {
  const finalImagePrimaryOption = product?.options?.find(optionValue =>
    VALID_IMAGE_OPTION_IDS.includes(optionValue.id)
  );
  const finalWoodImageOption = product?.options?.find(optionValue =>
    VALID_WOOD_OPTION_IDS.includes(optionValue.id)
  );
  let optionName = '';
  let finalSku = '';

  if (finalImagePrimaryOption) {
    let finalOptionValue = find(
      finalImagePrimaryOption.values[0].option_values,
      {
        id: parseInt(finalImagePrimaryOption.default_value, 10),
      }
    );

    if (!finalOptionValue) {
      // If no default value found fallback to first option
      const optionValue = finalImagePrimaryOption.values[0].option_values[0];
      finalOptionValue = optionValue;
    }
    if (
      [FABRIC_PRODUCT_OPTION_ID, LEATHER_PRODUCT_OPTION_ID].includes(
        finalImagePrimaryOption.id
      )
    ) {
      optionName = finalOptionValue ? finalOptionValue.value : '';
    }

    finalSku = finalOptionValue ? finalOptionValue.sku : '';
  }

  if (finalWoodImageOption) {
    let finalWoodOptionValue = find(
      finalWoodImageOption.values[0].option_values,
      {
        id: parseInt(finalWoodImageOption.default_value, 10),
      }
    );

    if (!finalWoodOptionValue) {
      // If no default value found fallback to first option
      const woodOptionValue = finalWoodImageOption.values[0].option_values[0];
      finalWoodOptionValue = woodOptionValue;
    }
    if (
      [FABRIC_PRODUCT_OPTION_ID, LEATHER_PRODUCT_OPTION_ID].includes(
        finalImagePrimaryOption.id
      )
    ) {
      finalSku += finalWoodOptionValue.sku;
    }
  }

  return { finalSku, optionName };
}

export default getDefaultOptionData;
