import isClient from 'commons/isClient';
import isServer from 'commons/isServer';

export function sendAnalyticsIdentifyEvent(userIdentifier, segmentTrackData) {
  if (isClient()) {
    window?.analytics?.identify?.(userIdentifier, segmentTrackData);
    // avo.identify(); CON-2364
  }
}

function sendExperimentToGAWithRetry(
  experimentId,
  variationId,
  retryCount = 0
) {
  if (!window.ga || !window.dataLayer) {
    if (retryCount > 5) {
      return;
    }
    setTimeout(
      () =>
        sendExperimentToGAWithRetry(experimentId, variationId, retryCount + 1),
      10000
    );
    return;
  }
  // eslint-disable-next-line no-console
  console.log(
    'Sending GA experiment metrics',
    `${experimentId}.${variationId}`
  );

  window.ga('set', 'exp', `${experimentId}.${variationId}`);
  window.ga('set', 'expId', experimentId);
  window.ga('set', 'expVar', variationId);
  window.dataLayer.push({
    event: 'optimize-experiment',
    'optimize-experimentId': experimentId,
    'optimize-variationId': variationId,
  });
}

export function sendGAExperimentData(experimentId, variationId) {
  if (isServer()) {
    return;
  }

  window?.rudderanalytics?.ready?.(() => {
    sendExperimentToGAWithRetry(experimentId, variationId);
  });
}

export function palomaPixelAddtoCart(data) {
  if (isClient()) {
    fetch('https://api.getpaloma.com/api/pixel/add_to_cart', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(data),
    })
      .then(d => d)
      .catch(e => e);
  }
}

export function palomaPixelPurchase(data) {
  if (isClient()) {
    fetch('https://api.getpaloma.com/api/pixel/purchase', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(data),
    })
      .then(d => d)
      .catch(e => e);
  }
}

export const ANALYTICS_EVENTS = {
  CREATE_ACCOUNT: 'Create Account',
  COUPON_ENTERED: 'Coupon Entered',
  COUPON_APPLIED: 'Coupon Applied',
  COUPON_DENIED: 'Coupon Denied',
  COUPON_REMOVED: 'Coupon Removed',
  EMAIL_ACQUISITION: 'Email Acquired',
  VIEW_CATEGORY_PAGE: 'Product List Viewed',
  PRODUCT_CLICK: 'Product Clicked',
  VIEW_PDP: 'Product Viewed',
  VIEW_HOME_PAGE: 'Viewed Home Page',
  ADD_TO_CART: 'Product Added',
  LOG_IN: 'Logged In',
  REMOVE_FROM_CART: 'Product Removed',
  VIEW_CART: 'Cart Viewed',
  CHECKOUT_STARTED: 'Checkout Started',
  CHECKOUT_STEP_VIEWED: 'Checkout Step Viewed',
  CHECKOUT_STEP_COMPLETED: 'Checkout Step Completed',
  ADD_TO_WISHLIST: 'Added to Wishlist',
  REMOVE_FROM_WISHLIST: 'Remove from Wishlist',
  APPLY_COUPON_CODE: 'Applied Coupon Code',
  VARIATION_SELECTION: 'Selected Variation',
  SWATCH_REQUEST: 'Requested Swatches',
  VIDEO: 'Video',
  PURCHASE: 'Order Completed',
  SEARCH: 'Search',
  FILTER_CATEGORY_PAGE: 'Product List Filtered',
  DESIGN_QUIZ_STARTED: 'DS Quiz Started',
  DESIGN_QUIZ_STEP_COMPLETED: 'DS Quiz Step Completed',
  DESIGN_QUIZ_COMPLETED: 'DS Quiz Completed',
  THEME_CARD_CLICK: 'Theme Card Clicked',
  STARTED_EXPERIMENT: 'Started Experiment',
  FINANCING_CLICK: 'Financing Click',
  FULL_SCREEN_CLICK: 'Full Screen Click',
  UPSELL_PRODUCT_ADDED: 'Upsell Product Added',
  UPHOLSTERY_FILTERED: 'Upholstery Filtered',
  PROTECTION_PLAN_ADDED: 'Protection Plan Added',
};

export default {
  ANALYTICS_EVENTS,
  sendAnalyticsIdentifyEvent,
};
