import React from 'react';
import classNames from 'classnames';

const SkipToContentLink = () => (
  <a
    className={classNames(
      'text-sm bg-gray-light9 right-[10%] absolute [transform:translateY(-150%)] [transition:transform_.3s] rounded p-[5px] text-gray mt-2.5 font-normal z-[1004]',
      '[&:link]:no-underline',
      'visited:no-underline',
      'hover:no-underline hover:text-brand',
      'active:no-underline',
      'focus:[transform:translateY(0)] focus:z-[1010]',
      'focus-visible:outline-white focus-visible:outline-offset-4'
    )}
    href="#skip-to-content"
  >
    Skip to content
  </a>
);

export default SkipToContentLink;
