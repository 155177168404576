import { find } from 'lodash';
import constructProductSKUImgPrefix from './constructProductSKUImgPrefix';
import getDefaultOptionData from './getDefaultOptionData';

function buildFinalHeroImageURL(
  productName,
  productId,
  finalSku,
  optionName,
  count,
  forCategory
) {
  const finalProductName = productName
    .trim()
    .toLowerCase()
    .split(' ')
    .filter(w => !!w)
    .join('-');

  let transparent_hero_image = constructProductSKUImgPrefix(
    productId,
    finalSku,
    'hero',
    encodeURIComponent(
      `${productId}${finalSku}-${finalProductName}${
        optionName ? `-` : ``
      }${optionName.trim().toLowerCase().split(' ').join('-')}-t1-${count}_t`
    )
  );
  if ((productName || '').includes('&')) {
    const encodedProductName = encodeURIComponent(finalProductName);
    transparent_hero_image = transparent_hero_image.replace(
      encodedProductName,
      finalProductName
    );
  }
  if (transparent_hero_image.includes('(')) {
    const encodedURL = transparent_hero_image.split('/');
    let lastSection = encodedURL.pop();
    lastSection = lastSection.replace('(', '%28');
    lastSection = lastSection.replace(')', '%29');
    encodedURL.push(lastSection);
    transparent_hero_image = encodedURL.join('/');
  }
  let transparent_hero_image_ts = null;
  if (forCategory) {
    transparent_hero_image_ts = `${transparent_hero_image}s.png`;
  }
  transparent_hero_image += `.png`;
  return { transparent_hero_image, transparent_hero_image_ts };
}

export function constructHeroImageURL(
  product,
  sku,
  count = 1,
  forCategory = false
) {
  let fabricSku = find(product.options, { name: 'Fabric' });
  const leatherSku = find(product.options, { name: 'Leather' });
  const woodSku = find(product.options, { name: 'Wood' });

  if (fabricSku && fabricSku.id && fabricSku.id !== 17) {
    // Handle Vendor Fabric
    fabricSku = null;
  }

  let { optionName, finalSku } =
    product?.options?.length && !sku
      ? getDefaultOptionData(product)
      : { optionName: '', finalSku: '' };

  if (sku) {
    finalSku = sku;
    const optionSkus = sku.split('-').filter(sk => !!sk);
    // Pick option name for sku
    if (fabricSku) {
      const fabricOptions = find(product.options, { name: 'Fabric' });
      optionName = find(fabricOptions.values[0].option_values, {
        sku: `-${optionSkus[0]}`,
      });
      optionName = optionName ? optionName.value : '';
    } else if (leatherSku) {
      const leatherOptions = find(product.options, { name: 'Leather' });
      optionName = find(leatherOptions.values[0].option_values, {
        sku: `-${optionSkus[0]}`,
      });
      optionName = optionName ? optionName.value : '';
    } else if (woodSku && !fabricSku && !leatherSku) {
      optionName = '';
    }
  }

  return buildFinalHeroImageURL(
    product.name,
    product.id,
    finalSku,
    optionName,
    count,
    forCategory
  );
}

export default buildFinalHeroImageURL;
