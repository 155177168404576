/**
 * 
   affiliate url: http://localhost:3007/?utm_source=iterable&utm_medium=email&utm_term=jbnewsletter&utm_content=catalina_sofa&utm_campaign=20240416_Flash_Sale_wp&campaignid=12576236&templateid=9518180
 * 
 */
const urlAffiliateFields = () => {
  const queryparams =
    window && window.location.search
      ? new URL(window.location).searchParams
      : null;

  if (!queryparams || !queryparams.get('utm_source')) return {};
  return {
    campaignUtmSource: queryparams.get('utm_source') || '',
    campaignUtmMedium: queryparams.get('utm_medium') || '',
    campaignUtmTerm: queryparams.get('utm_term') || '',
    campaignUtmContent: queryparams.get('utm_content') || '',
    campaignUtmCampaign: queryparams.get('utm_campaign') || '',
    iterableCampaignId: parseInt(queryparams.get('campaignid'), 10) || null,
    iterableTemplateId: parseInt(queryparams.get('templateid'), 10) || null,
  };
};

export default urlAffiliateFields;
